.disabled-clicking {
  pointer-events: none; }

.disabled-opacity {
  opacity: .5; }

.container {
  padding-left: 15px;
  padding-right: 15px; }

.toast.error-toast i.material-icons {
  position: absolute;
  top: 6px;
  right: 7px;
  font-size: 13px;
  cursor: pointer; }

.select-dropdown li.optgroup > span,
.select-dropdown li.disabled > span {
  color: rgba(0, 0, 0, 0.4) !important;
  cursor: not-allowed; }

.bottom-separator {
  border: solid #eee7e7;
  border-width: 0 0 1px;
  padding: 0 0 30px;
  margin: 0 0 30px; }

.flex {
  display: flex; }

.align-items-center {
  align-items: center; }

.text-align-end {
  text-align: end; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-between {
  justify-content: space-between; }

.btn[disabled] {
  opacity: 0.65; }

.hidden {
  display: none; }

.dev_page {
  box-shadow: inset 5px 5px red, inset -5px -5px red; }

abbr[title] {
  text-decoration: none; }

#factom .btn, #factom #csv-link-container a {
  border-radius: 2px;
  text-transform: none;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  height: 41px;
  line-height: 41px;
  transition: .3s ease-out;
  padding: 0 10px; }

#factom .btn.btn-primary, #factom #csv-link-container a, #factom .btn.btn-secondary {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

#factom .btn.btn-primary:hover, #factom #csv-link-container a:hover, #factom .btn.btn-secondary:hover {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2); }

#factom .btn.btn-primary, #factom #csv-link-container a {
  background-color: #125afa;
  color: #fff; }
  #factom .btn.btn-primary:hover, #factom #csv-link-container a:hover {
    background-color: #3a75f7; }
  #factom .btn.btn-primary:focus, #factom #csv-link-container a:focus {
    background-color: #4a82ff; }
  #factom .btn.btn-primary:disabled, #factom #csv-link-container a:disabled {
    background-color: #E1E1E1 !important;
    color: #A2A2A2 !important;
    box-shadow: none; }

#factom .btn.btn-outline {
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  justify-content: center;
  line-height: 40px; }

#factom .btn.btn-primary.btn-outline {
  color: #125afa;
  border-color: #125afa;
  background-color: transparent;
  box-shadow: none; }
  #factom .btn.btn-primary.btn-outline:hover {
    background-color: #ebf1fd; }
  #factom .btn.btn-primary.btn-outline:focus {
    background-color: #DAE4FB; }
  #factom .btn.btn-primary.btn-outline:disabled, #factom .btn.btn-primary.btn-outline[disabled] {
    color: #A2A2A2;
    border-color: #A2A2A2;
    box-shadow: none;
    background-color: transparent !important; }

#factom .btn.btn-secondary {
  background-color: #E8B521; }
  #factom .btn.btn-secondary:hover {
    background-color: #C8980F; }
  #factom .btn.btn-secondary:focus {
    background-color: #E8B317; }
  #factom .btn.btn-secondary:disabled, #factom .btn.btn-secondary[disabled] {
    background-color: #E1E1E1 !important;
    color: #A2A2A2 !important;
    box-shadow: none; }
  #factom .btn.btn-secondary.btn-outline {
    border-color: #E8B521;
    color: #E8B521;
    background-color: #fff; }

#factom .btn.btn-flat {
  background-color: transparent;
  font-weight: 400;
  height: auto;
  box-shadow: none; }
  #factom .btn.btn-flat.btn-primary {
    color: #388fc9; }
    #factom .btn.btn-flat.btn-primary:hover {
      background-color: transparent;
      box-shadow: none; }
    #factom .btn.btn-flat.btn-primary:disabled, #factom .btn.btn-flat.btn-primary[disabled] {
      background-color: transparent; }
    #factom .btn.btn-flat.btn-primary:focus {
      background-color: transparent; }

#factom .btn i {
  margin-right: 10px;
  font-size: 1.7rem; }

#factom #csv-link-container a {
  padding: 10px; }

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #388fc9 !important;
  box-shadow: 0 1px 0 0 #388fc9 !important; }

input[type="checkbox"]:checked + span:not(.lever)::before {
  border-right: 2px solid #125afa;
  border-bottom: 2px solid #125afa; }

input[type="checkbox"]:indeterminate + span:not(.lever)::before {
  border-bottom: none; }

input[type="checkbox"] + span:not(.lever)::before {
  width: 14px;
  height: 14px;
  z-index: 0;
  border: 1px solid #A2A2A2; }

input[type="checkbox"]:checked + span:not(.lever)::before {
  width: 8px;
  height: 16px; }

input[type="checkbox"]:not(:checked):disabled + span:not(.lever)::before {
  background-color: #A2A2A2; }

input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly]) + label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
.dropdown-content li > a,
.dropdown-content li > span {
  color: #388fc9 !important; }

input:not([type]),
input[type='text']:not(.browser-default),
input[type='password']:not(.browser-default),
input[type='email']:not(.browser-default),
input[type='url']:not(.browser-default),
input[type='time']:not(.browser-default),
input[type='date']:not(.browser-default),
input[type='datetime']:not(.browser-default),
input[type='datetime-local']:not(.browser-default),
input[type='tel']:not(.browser-default),
input[type='number']:not(.browser-default),
input[type='search']:not(.browser-default),
textarea.materialize-textarea {
  border-bottom: 1px solid #555;
  font-size: 1rem; }

textarea.materialize-textarea {
  padding: 1rem 0 0.8rem 0 !important; }

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #125afa !important; }

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #125afa !important; }

.btn,
.btn-large,
.btn-small {
  background-color: #125afa; }

.btn:focus,
.btn-large:focus,
.btn-small:focus,
.btn-floating:focus,
.btn:hover,
.btn-large:hover,
.btn-small:hover {
  background-color: #388fc9;
  color: #fff; }

.material-tooltip {
  transform: translateY(15px) !important; }

ul.stepper .row .col {
  padding: 0; }

ul.stepper:not(.horizontal) .step.active::before,
ul.stepper:not(.horizontal) .step.done::before,
ul.stepper.horizontal .step.active .step-title::before,
ul.stepper.horizontal .step.done .step-title::before {
  background-color: #125afa !important; }

ul.stepper > .step:not(:last-of-type):after {
  background: #eee7e7; }

@media only screen and (min-width: 769px) {
  ul.stepper.horizontal .step:not(:last-of-type):after,
  ul.stepper.horizontal > .step.active:not(:last-of-type):after {
    background: #eee7e7; } }

table.striped tr {
  height: 59px; }

table.striped > tbody > tr:nth-child(odd) {
  background-color: #fafafa; }
